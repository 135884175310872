<template>
    <div class="page-login">

        <div class="header">
            <router-link class="logo" to="/">
                <logo size="50" />
                <div class="logo-text">
                    <h5>七鸟互联</h5>
                    <span>7NIAO.NET</span>
                </div>
            </router-link>
            <div class="r-links">
                <weibo class="icon" theme="outline" size="20" fill="#fff"/>
                <wechat class="icon" theme="outline" size="20" fill="#fff"/>
                <github class="icon" theme="outline" size="20" fill="#fff"/>
                <translate class="icon" theme="outline" size="22" fill="#fff"/>
            </div>
        </div>

        <login-form class="login-form"></login-form>


        <div class="bottom-box">Copyright © 2020 7NIAO.NET</div>

        <div class="top-bg"></div>
        <div class="bottom-bg"></div>
    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Weibo, Wechat, Github, Translate } from '@icon-park/vue-next';
import LoginForm from '@/components/forms/LoginForm.vue';
import Logo from '@/components/layout/Logo.vue';

@Options({
    components: {
        Weibo, Wechat, Github, Translate,
        LoginForm, Logo
    },
    mounted(){
        const accessToken = this.$store.state.accessToken;
        if(accessToken) this.$router.push({ path: '/admin' })
    }
})

export default class NLogin extends Vue {}
</script>

<style lang="scss" scoped>
.page-login{
    @extend .flex-column;
    position: relative;
    width: 100%;
    height: 100%;
    .header {
        @extend .flex-row-between;
        position: relative;
        z-index: 3;
        width: 100%;
        height: 70px;
        padding: 10px;
        .logo {
            @extend .flex-row;
            .img {
                margin-right: 5px;
                background-image: linear-gradient(45deg, $linear-white);
            }
            .logo-text {
                @extend .flex-column;
                margin: 5px 0 5px 10px;
                color: $white;
                h5 {
                    margin: 0;
                    font-size: 18px;
                    font-weight: 500;
                }
                span {
                    opacity: 0.8;
                    margin: 2px 0 0 1px;
                    letter-spacing: 1px;
                    font-size: 10px;
                }
            }
        }
        .r-links {
            @extend .flex-row;
            .icon {
                @extend .flex-column-center;
                cursor: pointer;
                width: 50px;
                height: 50px;
            }
            .icon:hover{
                opacity: .5;
            }
        }
    }
    
    .login-form{
        z-index: 9999;
        position: absolute;
        left: 50%;
        top: 30%;
        margin-left: -170px;
    }

    .bottom-box {
        z-index: 3;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 15px 0;
        text-align: center;
        font-size: 12px;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
        color: rgba(255, 255, 255, 0.2);
    }
    .top-bg {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(45deg, $linear-primary);
    }
    .bottom-bg {
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 80px;
        background-color: $dark;
        &::before {
            position: absolute;
            right: 0;
            bottom: 80px;
            width: 0;
            height: 0;
            display: block;
            content: "";
            border-width: 0 0 300px 2700px;
            border-style: solid;
            border-color: transparent transparent $dark transparent;
        }
    }
}
</style>