
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        some: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            mode: 'login',
            form: {
                username: '',
                password: '',
                code: '',
                identifyCodes: '1234567890',
                identifyCode: '',
                remember: false
            },
            register: {
                phone: '',
                identifyCodes: '1234567890',
                identifyCode: '',
                code: '',
                agree: false
            },
            loginBtn: {
                loading: false,
                disabled: false
            },
            loading: false,
            formMsg: {
                username: '',
                password: '',
                code: ''
            }
        }
    },
    emits: {
        login: () => {
            return true;
        }
    },
    watch:{
        'form.code'(nVal){
            if(nVal != this.form.identifyCode) this.formMsg.code = '验证码错误';
            else this.formMsg.code = '';
        }
    },
    mounted(){
        this.form.identifyCode = "";
        this.makeCode(this.form.identifyCodes, 4);
    },
    methods: {
        // 生成一个随机数
        randomNum(min: number, max: number) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        refreshCode() {
            this.form.identifyCode = "";
            this.makeCode(this.form.identifyCodes, 4);
        },
        makeCode(o: any, l: any) {
            for (let i = 0; i < l; i++) {
                this.form.identifyCode += this.form.identifyCodes[
                    this.randomNum(0, this.form.identifyCodes.length)
                ];
            }
        },
        handlerLogin(){
            const form = this.form;
            this.$api.login(form.username, form.password).then((res: any) => {
                if(res.msg === 'success' && res.data.access_token){
                    const accessToken = `Bearer ${res.data.access_token}`
                    if(form.remember) {
                        localStorage.setItem('accessToken', accessToken);
                    } else {
                        sessionStorage.setItem('accessToken', accessToken);
                    }
                    this.$store.commit('changeAccessToken', accessToken)
                    this.$router.push('/admin');
                } else {
                    if(res.data === '用户不存在') this.formMsg.username = res.data;
                    else this.formMsg.password = res.data;
                    this.loginBtn.loading = false;
                    this.loginBtn.disabled = false;
                }
            })
        },
        // 检查用户名是否存在
        checkUserName(){
            if(this.form.username.length > 3)
                this.$api.checkUserExits(this.form.username, 'admin').then((res: any) => {
                    if(res === 'noexits') this.formMsg.username = '用户不存在';
                    else this.formMsg.username = '';
                });
        }
    }
})

export default class LoginForm extends Vue {}
