<template>

    <div class="login-form">

        <div class="form-head">
            <div class="one" :class="mode === 'login' ? 'current' : ''"
                @click="mode = 'login'"
            >
                <b>登录</b>
                <span></span>
            </div>
        </div>

        <!-- 登录 -->
        <template v-if="mode === 'login'">
            <!-- 表单 -->
            <div class="form-container">
                <div class="form-item">
                    <n-input 
                        v-model:value="form.username" 
                        prefix="n-user" 
                        placeholder="用户名/手机号/邮箱"
                        v-model:msg="formMsg.username"
                        clear round
                        @blur="checkUserName"
                    ></n-input>
                </div>
                <div class="form-item">
                    <n-input 
                        v-model:value="form.password" 
                        prefix="n-lock" 
                        type="password"
                        placeholder="密码"
                        v-model:msg="formMsg.password"
                        :disabled="formMsg.username !== ''"
                        showPassword round
                    ></n-input>
                </div>
                <div class="form-item">
                    <n-input
                        v-model:value="form.code"
                        v-model:msg="formMsg.code"
                        placeholder="验证码"
                        round
                    ></n-input>
                    <n-captcha 
                        class="captcha-code" round
                        :identifyCode="form.identifyCode" 
                        @click="refreshCode"
                    ></n-captcha>
                </div>
                <div class="form-item remember">
                    <n-checkbox class="checkbox" v-model:checked="form.remember">记住密码</n-checkbox>
                </div>
                <div class="form-item">
                    <n-button 
                        class="btn"
                        type="primary"
                        size="large"
                        :loading="loginBtn.loading"
                        :disabled="form.username.length < 3 || form.password.length < 6 || form.identifyCode != form.code"
                        @click="handlerLogin(); loginBtn.loading = true; loginBtn.disabled = true"
                    >登 录</n-button>
                </div>
            </div>
        </template>

    </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
    props: {
        some: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            mode: 'login',
            form: {
                username: '',
                password: '',
                code: '',
                identifyCodes: '1234567890',
                identifyCode: '',
                remember: false
            },
            register: {
                phone: '',
                identifyCodes: '1234567890',
                identifyCode: '',
                code: '',
                agree: false
            },
            loginBtn: {
                loading: false,
                disabled: false
            },
            loading: false,
            formMsg: {
                username: '',
                password: '',
                code: ''
            }
        }
    },
    emits: {
        login: () => {
            return true;
        }
    },
    watch:{
        'form.code'(nVal){
            if(nVal != this.form.identifyCode) this.formMsg.code = '验证码错误';
            else this.formMsg.code = '';
        }
    },
    mounted(){
        this.form.identifyCode = "";
        this.makeCode(this.form.identifyCodes, 4);
    },
    methods: {
        // 生成一个随机数
        randomNum(min: number, max: number) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        refreshCode() {
            this.form.identifyCode = "";
            this.makeCode(this.form.identifyCodes, 4);
        },
        makeCode(o: any, l: any) {
            for (let i = 0; i < l; i++) {
                this.form.identifyCode += this.form.identifyCodes[
                    this.randomNum(0, this.form.identifyCodes.length)
                ];
            }
        },
        handlerLogin(){
            const form = this.form;
            this.$api.login(form.username, form.password).then((res: any) => {
                if(res.msg === 'success' && res.data.access_token){
                    const accessToken = `Bearer ${res.data.access_token}`
                    if(form.remember) {
                        localStorage.setItem('accessToken', accessToken);
                    } else {
                        sessionStorage.setItem('accessToken', accessToken);
                    }
                    this.$store.commit('changeAccessToken', accessToken)
                    this.$router.push('/admin');
                } else {
                    if(res.data === '用户不存在') this.formMsg.username = res.data;
                    else this.formMsg.password = res.data;
                    this.loginBtn.loading = false;
                    this.loginBtn.disabled = false;
                }
            })
        },
        // 检查用户名是否存在
        checkUserName(){
            if(this.form.username.length > 3)
                this.$api.checkUserExits(this.form.username, 'admin').then((res: any) => {
                    if(res === 'noexits') this.formMsg.username = '用户不存在';
                    else this.formMsg.username = '';
                });
        }
    }
})

export default class LoginForm extends Vue {}
</script>

<style lang="scss" scoped>
.login-form{
    @extend .flex-column;
    width: 340px;
    padding-bottom: 10px;
    border-radius: $radius-big;
    background-color: $white;
    box-shadow: $shadow;

    // 表单头部  登录|注册
    .form-head{
        @extend .flex-row;
        padding: 0 20px;
        .one{
            @extend .flex-column-center;
            padding: 15px 0;
            b{
                cursor: pointer;
                padding: 5px 10px;
                font-size: 15px;
                color: $grey;
            }
            span{
                opacity: 0;
                width: 15px;
                height: 4px;
                border-radius: $radius-round;
                background-color: $primary;
            }
            &:hover{
                b{ color: $text-color; }
            }
            &.current{
                b{ color: $text-color; }
                span{ opacity: 1; }
            }
        }
    }

    // 表单主体
    .form-container{
        padding: 10px 20px;
        .form-item{
            @extend .flex-row;
            margin-bottom: 15px;
            &:last-child{
                margin-bottom: 0;
            }
            &.remember{
                @extend .flex-row-between;
                padding: 0 5px;
                .checkbox{
                    color: $grey;
                }
                .links{
                    @extend .flex-row;
                    span{
                        @extend .flex-column-middle;
                        margin: 0 5px;
                        color: $info;
                    }
                    .link{
                        @extend .flex-column-middle;
                        color: $grey;
                        &:hover{
                            color: $text-color;
                        }
                    }
                }
            }
            .register-agree{
                @extend .flex-row;
                a{
                    color: $primary;
                }
            }
            .captcha-code{
                margin-left: 10px;
                border-radius: $radius-round;
            }
            .btn{
                width: 100%;
            }
        }
    }

    .form-footer{
        @extend .flex-column-center;
        padding: 10px 0 15px;
        &.register-footer{
            @extend .flex-row-between;
            padding: 10px 20px 20px;
            .label{
                @extend .flex-column-middle;
                color: $grey;
            }
        }
        .other-mode{
            @extend .flex-row;
            height: 33px;
            .link{
                @extend .flex-column-middle;
                cursor: pointer;
            }
            .fenge{
                @extend .flex-column-middle;
                margin: 0 15px;
                color: $info;
            }
            .icon-one{
                @extend .flex-column-center;
                .n-icon{
                    @extend .flex-column-center;
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                    margin-right: 10px;
                    border-radius: $radius-circle;
                    font-size: 18px;
                    &.n-wechat-fill{
                        color: $success;
                        background-color: $success-light;
                        &:hover{
                            background-color: $success;
                            color: $white;
                        }
                    }
                    &.n-weibo-fill{
                        color: $danger;
                        background-color: $danger-light;
                        &:hover{
                            background-color: $danger;
                            color: $white;
                        }
                    }
                    &.n-github-fill{
                        color: $dark;
                        background-color: $dark-light;
                        &:hover{
                            background-color: $dark;
                            color: $white;
                        }
                    }
                }
            }
        }
        .footer-text{
            padding: 10px 0;
            color: $grey;
        }
        .link{
            opacity: 1;
            color: $primary;
            &:hover{
                opacity: .7;
            }
        }
    }
}
</style>