
import { Options, Vue } from 'vue-class-component';
import { Weibo, Wechat, Github, Translate } from '@icon-park/vue-next';
import LoginForm from '@/components/forms/LoginForm.vue';
import Logo from '@/components/layout/Logo.vue';

@Options({
    components: {
        Weibo, Wechat, Github, Translate,
        LoginForm, Logo
    },
    mounted(){
        const accessToken = this.$store.state.accessToken;
        if(accessToken) this.$router.push({ path: '/admin' })
    }
})

export default class NLogin extends Vue {}
